import React, { Component, createRef } from 'react';

import Helmet from 'react-helmet';
import * as typeformEmbed from '@typeform/embed';

const { GATSBY_TYPEFORM_URL } = process.env;

class HelpHotlinePage extends Component {
  widgetRef = createRef();

  componentDidMount() {
    typeformEmbed.makeWidget(this.widgetRef.current, GATSBY_TYPEFORM_URL);
  }

  render() {
    return (
      <>
        <div ref={this.widgetRef} style={{ width: '100%', height: '100vh' }} />
        <script
          type="text/javascript"
          src="https://embed.typeform.com/embed.js"
        />
        <Helmet title="Help Hotline" />
      </>
    );
  }
}

export default HelpHotlinePage;
